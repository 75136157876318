import logo from './logo.svg';
import './App.css';

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {

  apiKey: "AIzaSyAO4IOJWRzcilwrCfWD4ltvpk1m6KbQVfA",

  authDomain: "rattlebust.firebaseapp.com",

  projectId: "rattlebust",

  storageBucket: "rattlebust.appspot.com",

  messagingSenderId: "822513287408",

  appId: "1:822513287408:web:34488e32db2d7a72c84235",

  measurementId: "G-HX9JV5759N"

};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

function MainStory() {
  return (
    <div>
        <h1 className='mainstoryH1'><a href="#" className="mainstoryA">Tucker Carlson fired from Fox News</a></h1>
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <header>
        <img src={logo} className="App-logo" alt="logo" />
        <h3>Welcome to Rattlebust!</h3>
        <MainStory />
      </header>
    </div>
  );
}

export default App;
